/**
 * api接口调用
 */

import axios from 'axios'
// import { Message } from 'element-ui'
// import store from './../store'
// 创建axios实例 
axios.defaults.withCredentials = true;
console.log(process.env.VUE_APP_API_BASE_URL)
var requestUrl = process.env.VUE_APP_API_BASE_URL
if(window.location.href.includes('vbeta.ourgame.com')  || window.location.href.includes('video-qa.ourgame.com/') || window.location.href.includes('v.ourgame.com')){
  requestUrl = window.location.protocol+'/video/'
}
// 'http://v.ourgame.com/video/'
const service = axios.create({
  // baseURL: window.location.protocol+'/video/',
  baseURL:requestUrl,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8'
  },
  withCredentials: true,
  transformRequest: [function (data) {
    // let ret = ''
    // for (const it in data) {
    //   ret += encodeURIComponent(it) + '=' + encodeURIComponent(data[it]) + '&'
    // }
    // console.log(ret)
    // console.log(JSON.stringify(ret))
    // console.log(JSON.parse(ret)) 
    data = JSON.stringify(data)
    return data
  }],
  timeout: 0
})
// request拦截器
service.interceptors.request.use(
  config => {
    config.url = config.url+'?v='+Math.random()
    return config
  },
  error => {
    Promise.reject(error)
  })

// respone拦截器
service.interceptors.response.use(
  response => {
    if (response.data.code === '401') {
      alert(response.data.msg)
    } else {
      return response
    }
  },
  error => {
    return Promise.reject(error)
  }
)

export default service