<template>
    <div class="videoStatistics">
        <el-tabs align="left" v-model="activeName" @tab-click="handleClick">
            <el-tab-pane align="left" label="视频管理" name="1">
                <ul class="manageSubnav">
                    <li v-for="(item,index) in subNavList" :key="index" @click="subNavTab(index)"
                        :class="subNavChecked == index?'manageSubnavCheck':''">{{item}}{{subNumlist[index]}}</li>
                </ul>
                <div class="manageContent">
                    <el-empty v-if="listData.length == 0" image="http://image.ourgame.com/videoWeb/null.png"
                        description=" "></el-empty>
                    <!--全部-->
                    <div class="totalvideo" v-if="subNavChecked == 0">
                        <ul>
                            <li v-for="(item,index) in listData"
                                @click="jump(item)" :key="index"> 
                                <img :src="item.coverUrl" alt="">
                                <p>{{item.videoTitle}}</p>
                            </li>
                        </ul>
                    </div>
                    <!---->
                    <div class="videoStatus" v-if="subNavChecked != 0">
                        <div class="videoMange_box" v-for="(item,index) in listData" :key="index">
                            <div class="videoMangeBox_pic"
                                @click="jump(item)">
                                <img :src="item.coverUrl" alt="">
                                <!--   // <p>{{item.videoTitle}}</p>-->
                            </div>
                            <div class="videoMangeBox_text">
                                <p class="videoStatus_title">{{item.videoTitle}}</p>
                                <div class="videoMangeBox_btn">
                                    <p @click="jumpUpload(item.videoID)" v-if="subNavChecked != 2"><span
                                            class="iconfont icon-xiugai"></span>编辑</p>
                                    <p @click="delVideoFnConfirm(item.videoID)"><span
                                            class="iconfont icon-shanchu-01"></span>删除</p>
                                </div>
                                <div class="videoMange_time">
                                    <p>投稿时间 ：{{item.recDate}}</p>
                                    <p v-if="subNavChecked == 1" class="reason">审核中</p>
                                    <p v-if="subNavChecked == 2" class="reason">通过时间：{{item.approveDate}}</p>
                                    <p v-if="subNavChecked == 3" class="reason" style="color:red;">未通过原因:{{item.approveContent}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <pagination v-show="total>0" :total="total" :page.sync="listQuery.current"
                        :limit.sync="listQuery.size" @pagination="getList" />
                </div>
            </el-tab-pane>

        </el-tabs>
        <!-- 删除视频 -->
        <div class="modal" v-show="deleteInfo">
            <img @click="closeModal" src="~assets/close_icon.png" alt="">
            <p>您确定是否要删除该条视频</p>
            <button @click="delVideoFn">确定</button>
            <button @click="closeModal">取消</button>
        </div>
    </div>
</template>

<script>
    import pagination from '@/components/pagination.vue'
    import {
        videoAdministration,
        delVideo,
        searchCnt
    } from '@/api/authority'
    export default {
        name: "videoStatistics",
        components: {
            pagination
        },
        data() {
            return {
                a: 0,
                activeName: '1',
                subNavList: [
                    '全部视频',
                    '审核中',
                    '已通过',
                    '未通过',
                ],
                subNumlist: [],
                subNavChecked: 0,
                listQuery: {
                    approveType: '',
                    current: 1,
                    size: 3,
                },
                deleteInfo:false,
                listData: {},
                total: 0,
                searchCnt: {},
                content: '',
                comment: '',
                commentList: {
                    commentLevel: '',
                    content: '',
                    parentCommentID: '',
                    parentCommentUsername: '',
                    replyCommentID: '',
                    replyCommentUsername: '',
                    username: '',
                    videoID: '',
                }
            };
        },
        mounted() {
            this.pageSizeFn()
            this.getList()
            this.getTotalNum()
        },
        methods: {
            jump(item){
                this.$store.commit('JUMP_PLAY',{alid:item.aliVideoId,videoId:item.videoID,vue:this})
            },
            handleEmotion(i) {
                this.content += i
            },
            // 将匹配结果替换表情图片
            emotion(res) {
                let word = res.replace(/\#|\;/gi, '')
                const list = ['微笑', '撇嘴', '色', '发呆', '得意', '流泪', '害羞', '闭嘴', '睡', '大哭', '尴尬', '发怒', '调皮', '呲牙', '惊讶',
                    '难过', '酷', '冷汗', '抓狂', '吐', '偷笑', '可爱', '白眼', '傲慢', '饥饿', '困', '惊恐', '流汗', '憨笑', '大兵', '奋斗',
                    '咒骂', '疑问', '嘘', '晕', '折磨', '衰', '骷髅', '敲打', '再见', '擦汗', '抠鼻', '鼓掌', '糗大了', '坏笑', '左哼哼', '右哼哼',
                    '哈欠', '鄙视', '委屈', '快哭了', '阴险', '亲亲', '吓', '可怜', '菜刀', '西瓜', '啤酒', '篮球', '乒乓', '咖啡', '饭', '猪头',
                    '玫瑰', '凋谢', '示爱', '爱心', '心碎', '蛋糕', '闪电', '炸弹', '刀', '足球', '瓢虫', '便便', '月亮', '太阳', '礼物', '拥抱',
                    '强', '弱', '握手', '胜利', '抱拳', '勾引', '拳头', '差劲', '爱你', 'NO', 'OK', '爱情', '飞吻', '跳跳', '发抖', '怄火',
                    '转圈', '磕头', '回头', '跳绳', '挥手', '激动', '街舞', '献吻', '左太极', '右太极'
                ]
                let index = list.indexOf(word)
                return `<img src="https://res.wx.qq.com/mpres/htmledition/images/icon/emotion/${index}.gif" align="middle">`
            },
            closeModal(){
                this.deleteInfo = false
            },
            // 列表切换
            subNavTab(index) {
                this.subNavChecked = index
                this.listQuery.approveType = index - 1
                if (index - 1 < 0) {
                    this.listQuery.approveType = ''
                }
                this.listQuery.current = 1
                this.pageSizeFn()
                this.getList()
            },
            // 不同分辨率size改变
            pageSizeFn() {
                if (window.screen.width <= 1600 && this.subNavChecked == 0) {
                    this.listQuery.size = 12
                } else if (window.screen.width >= 1400 && this.subNavChecked == 0) {
                    this.listQuery.size = 15
                } else {
                    this.listQuery.size = 3
                }
            },
            handleClick(tab, event) {
                console.log(tab, event);
            },

            // 跳转发布视频页
            jumpUpload(videoId) {
                this.$router.push({
                    path: '/secondary/releaseVideo',
                    query: {
                        videoId
                    }
                })
            },
            // 分类总数量
            getTotalNum() {
                searchCnt().then(response => {
                    if (response.data.code == 0) {
                        this.subNumlist = []
                        for (let i in response.data.data) {
                            this.subNumlist.push(response.data.data[i])
                        }
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 删除视频
            delVideoFnConfirm(videoID) {
                this.deleteInfo = true
                this.deleteVideoId = videoID
            },
            delVideoFn(){
                delVideo({
                    videoID:this.deleteVideoId
                }).then(response => {
                    if (response.data.code == 0) {
                        this.getList()
                        this.deleteInfo = false
                        this.getTotalNum()
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            },
            // 获取列表
            getList(parm) {
                if (typeof parm != 'undefined') {
                    this.listQuery.current = parm.page
                }
                videoAdministration(this.listQuery).then(response => {
                    if (response.data.code == 0) {
                        this.listData = response.data.data.records
                        this.total = response.data.data.total
                    } else {
                        this.$message({
                            'message': response.data.msg
                        })
                    }
                })
            }
        }
    }
</script>
<style lang="less">
 .videocover(){
    float:left;
    position:relative;
    width: 222px;
    height: 178px;
    overflow:hidden;
    border-radius: 9px;
    top:0px;
    left:0px;
    img{
       width: 222px;
       height: 178px;
    }
    p{
       overflow: hidden;
       text-overflow:ellipsis;
       white-space: nowrap;
       width:204px;
       position: absolute;
       top:155px;
       left:13px;
       color:white;
       font-size: 14px;
    }
}
    .videoStatistics {
        .manageSubnav {
            li {
                border-right: 1px solid #999999;
                font-size: 14px;
                color: #999999;
                width: 86px;
                height: 16px;
                text-align: center;
                float: left;
                line-height: 16px;
                cursor: pointer;

                &:last-child {
                    border: 0px;
                }
            }

            .manageSubnavCheck {
                color: #00a1d6;
            }
        }

        .manageContent {
            padding-top: 20px;
            clear: both;
            min-height: 660px;

            .totalvideo {

                ul {
                    margin: -20px 0px 0px -20px;
                    height: 596px;

                    li {
                        margin: 20px 0px 0px 20px;
                        cursor: pointer;
                        .videocover()
                    }
                }
            }

            .videoStatus {
                .videoMange_box {
                    margin-top: 40px;
                    overflow: hidden;
                    clear: both;

                    .videoMangeBox_pic {
                        float: left;
                        .videocover();

                        img {
                            cursor: pointer;
                        }
                    }

                    .videoMangeBox_text {
                        padding-left: 23px;
                        float: left;
                        font-size: 14px;
                        width: 952px;

                        .videoStatus_title {
                            color: #333333;
                            font-size: 18px;
                            padding-bottom: 63px;
                        }

                        .videoMangeBox_btn {
                            float: right;

                            p {
                                float: right;
                                padding-right: 43px;
                                color: #cecece;

                                cursor: pointer;

                                &:hover {
                                    color: #00a1d6;
                                }
                            }
                        }

                        .videoMange_time {
                            padding-top: 70px;

                            p {
                                color: #666666;
                                float: left;
                            }

                            .reason {
                                color: #ff8a00;
                                padding-left: 246px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media screen and (max-width: 1600px) {
        .videoMangeBox_text {
            width: 702px !important;
        }

        .reason {
            padding-left: 120px !important;
        }
    }
</style>