<template>
  <!-- 分页 -->
  <div style="clear:both;text-align:center;padding:40px 0px;">
    <el-pagination :background="background" :current-page="currentPage" :page-size="pageSize" :layout="layout"
      :total="total" v-bind="$attrs" @size-change="handleSizeChange" @current-change="handleCurrentChange">
    </el-pagination>
  </div>
</template>

<script>
  export default {
    name: 'Pagination',
    props: {
      total: {
        required: true,
        type: Number
      },
      page: {
        type: Number,
        default: 1
      },
      limit: {
        type: Number,
        default: 20
      },
      pageSizes: {
        type: Array,
        default () {
          return [10, 20, 30, 50]
        }
      },
      layout: {
        type: String,
        // default: 'total, sizes, prev, pager, next, jumper'
        default: 'pager'
      },
      background: {
        type: Boolean,
        default: true
      },
      autoScroll: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      currentPage: {
        get() {
          return this.page
        },
        set(val) {
              console.log()
        // console.log('分页,,,,,,,,,,,,,,,,,,,,,,,'+val)
          this.$emit('update:page', val)
        }
      },
      pageSize: {
        get() {
          return this.limit
        },
        set(val) {
   
          // 子组件里改变这个变量并传给父组件
          this.$emit('update:limit', val)
        }
      }
    },
    methods: {
      handleSizeChange(val) {
        // 向父级转递pagination
        console.log()
        // console.log('分页>>>>>>>>>>>>>>>>>>>'+this.currentPage)
        this.$emit('pagination', {
          page: this.currentPage,
          limit: val
        })
      },
      handleCurrentChange(val) {
         console.log('')
        
        this.currentPage = val
        // console.log('分页----------------'+this.currentPage)
        // console.log('分页----------------'+val)
        this.$emit('pagination', {
          page: val,
          limit: this.pageSize
        })
      }
    }
  }
</script>

<style>

</style>