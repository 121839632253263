import request from '../untils/request'

/**
 * 获取获取视频上传地址和凭证
 */
export function createUploadVideo(data) {
  return request({
    url: 'aliyun/createUploadVideo',
    method: 'get',
    params: data
  })
}
/**
 * 刷新视频凭证
 */
export function refreshUploadVideo(data) {
  return request({
    url: 'aliyun/refreshUploadVideo',
    method: 'get',
    params: data
  })
}
/**
 * 视频分类
 */
export function getVideoClass(data) {
  return request({
    url: 'mainPage/getVideoClass',
    method: 'get',
    params: data
  })
}
/**
 * 发布视频
 */
export function publish(data) {
  return request({
    url: 'videoPublish/publish',
    method: 'post',
    data
  })
}
/**
 * 修改视频
 */
export function editVideo(data) {
  return request({
    url: 'videoPublish/edit',
    method: 'post',
    data
  })
}
/**
 * 内容管理
 */
export function videoAdministration(data) {
  return request({
    url: 'videoPublish/search',
    method: 'get',
    params: data
  })
}
/**
 * 获取播放凭证
 */
export function getVideoPlayAuth(data) {
  return request({
    url: 'aliyun/getVideoPlayAuth',
    method: 'get',
    params: data
  })
}
/**
 * 视频详情
 */
export function getVideoDetailByVideoID(data) {
  return request({
    url: 'videoPlay/getVideoDetailByVideoID',
    method: 'get',
    params: data
  })
}
/**
 * 发送弹幕
 */
export function bullet(data) {
  return request({
    url: 'videoPlay/bullet',
    method: 'post',
    data
  })
}
/**
 * 弹幕列表
 */
export function getVideoBulletByVideoID(data) {
  return request({
    url: 'videoPlay/getVideoBulletByVideoID',
    method: 'get',
    params: data
  })
}
/**
 * 视频统计
 */
export function statistics(data) {
  return request({
    url: 'videoPublish/statistics',
    method: 'get',
    params: data
  })
}
/**
 * 删除视频
 */
export function delVideo(data) {
  return request({
    url: 'videoPublish/del',
    method: 'get',
    params: data
  })
}
/**
 * 收藏视频
 */
export function collectVideo(data) {
  return request({
    url: 'videoPlay/collect',
    method: 'get',
    params: data
  })
}
/**
 * 取消收藏视频
 */
export function cancelCollectVideo(data) {
  return request({
    url: 'videoPlay/cancelCollect',
    method: 'get',
    params: data
  })
}
/**
 * 点赞
 */
export function goodVideo(data) {
  return request({
    url: 'videoPlay/good',
    method: 'get',
    params: data
  })
}
/**
 * 取消点赞
 */
export function cancelGoodVideo(data) {
  return request({
    url: 'videoPlay/cancelGood',
    method: 'get',
    params: data
  })
}
/**
 * 视频右侧推荐列表
 */
export function getPlayList2ByUsername(data) {
  return request({
    url: 'videoPlay/getPlayList2ByUsername',
    method: 'get',
    params: data
  })
}
/**
 * 播放视频数量
 */
export function playVideo(data) {
  return request({
    url: 'videoPlay/play',
    method: 'get',
    params: data
  })
}
/**
 * 播放视频数量
 */
export function transfer(data) {
  return request({
    url: 'videoPlay/transfer',
    method: 'get',
    params: data
  })
}
/**
 * 获取礼物列表
 */
export function getGifts(data) {
  return request({
    url: 'videoPlay/getGifts',
    method: 'get',
    params: data
  })
}
/**
 * 获取礼物列表
 */
export function searchCnt(data) {
  return request({
    url: 'videoPublish/searchCnt',
    method: 'get',
    params: data
  })
}
/**
 * 按标题搜索
 */
export function searchByTitle(data) {
  return request({
    url: 'search/searchByTitle',
    method: 'get',
    params: data
  })
}
/**
 * 按作者搜索
 */
export function searchByUsername(data) {
  return request({
    url: 'search/searchByUsername',
    method: 'get',
    params: data
  })
}
/**
 * 赠送礼物
 */
export function presentGiftCoin(data) {
  return request({
    url: 'videoPlay/presentGiftCoin',
    method: 'post',
    data
  })
}
/**
 * 查询礼物
 */
export function presentGiftIsCoin(data) {
  return request({
    url: 'videoPlay/presentGiftIsCoin',
    method: 'post',
    params: data
  })
}
/**
 * 打赏列表
 */
export function getVideoGiftByVideoID(data) {
  return request({
    url: 'videoPlay/getVideoGiftByVideoID',
    method: 'get',
    params: data
  })
}
/**
 * 热门
 */
export function getPopularList(data) {
  return request({
    url: 'ejPage/getPopularList',
    method: 'get',
    params: data
  })
}
/**
 * 最新
 */
export function getNewList(data) {
  return request({
    url: 'ejPage/getNewList',
    method: 'get',
    params: data
  })
}
/**
 * 二级分类列表
 */
export function getVideosecondyClass(data) {
  return request({
    url: 'ejPage/getVideoClass',
    method: 'get',
    params: data
  })
}
/**
 * 二级分类
 */
export function getVideoejPageByClassID(data) {
  return request({
    url: 'ejPage/getVideoByClassID',
    method: 'get',
    params: data
  })
}
/**
 * 发送评论
 */
export function comment(data) {
  return request({
    url: 'videoPlay/comment',
    method: 'post',
    data
  })
}
/**
 * 获取一级评论列表
 */
export function getVideoComment1ByVideoID(data) {
  return request({
    url: 'videoPlay/getVideoComment1ByVideoID',
    method: 'get',
    params: data
  })
}
/**
 * 获取一级评论列表
 */
export function getVideoComment2ByVideoID(data) {
  return request({
    url: 'videoPlay/getVideoComment2ByVideoID',
    method: 'get',
    params: data
  })
}
/**
 * 给评论点赞
 */
export function good4Comment(data) {
  return request({
    url: 'videoPlay/good4Comment',
    method: 'get',
    params: data
  })
}
/**
 * 主页轮播图
 */
export function getHomePush(data) {
  return request({
    url: 'mainPage/getHomePush',
    method: 'get',
    params: data
  })
}
/**
 * 主页热门视频推荐
 */
export function getHotPush(data) {
  return request({
    url: 'mainPage/getHotPush',
    method: 'get',
    params: data
  })
}
/**
 * 消息
 */
export function myMessage(data) {
  return request({
    url: 'mainPage/myMessage',
    method: 'get',
    params: data
  })
}
/**
 * 举报
 */
export function complain4Comment(data) {
  return request({
    url: 'videoPlay/complain4Comment',
    method: 'get',
    params: data
  })
}
/**
 * 删除评论
 */
export function del4Comment(data) {
  return request({
    url: 'videoPlay/del4Comment',
    method: 'get',
    params: data
  })
}
/**
 * 首页咨询
 */
export function getConfigNotice(data) {
  return request({
    url: 'mainPage/getConfigNotice',
    method: 'get',
    params: data
  })
}
/**
 * 评论屏蔽
 */
export function mask(data) {
  return request({
    url: 'videoPlay/mask',
    method: 'get',
    params: data
  })
}
/**
 * 视频详情
 */
export function getConfigNoticeDetail(data) {
  return request({
    url: 'mainPage/getConfigNoticeDetail',
    method: 'get',
    params: data
  })
}
/**
 * 作者下的所有视频
 */
export function listVideosByUsername(data) {
  return request({
    url: 'search/listVideosByUsername',
    method: 'get',
    params: data
  })
}
/**
 * 未读消息
 */
export function myUnreadMsgCnt(data) {
  return request({
    url: 'mainPage/myUnreadMsgCnt',
    method: 'get',
    params: data
  })
}